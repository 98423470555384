import "../style/about.css";
import imgMotivation from "../images/motivatione.png";
import imgOeil from "../images/oeil.webp";
import imgFeu from "../images/imgBenjamin.jpg";
import imgTexte from "../images/texte.webp";

function About() {
  return (
    <div className="container about">
      <h2>A Propos</h2>
      <img src={imgMotivation} alt="couché de soleil" />
      <div className="separation"></div>
      <p>
        Pendant l'enfance j'ai toujours senti un décalage avec les autres. Ce
        n'était pas facile de s'intégrer.
        <br /> Cette incompréhension du monde qui m'entoure et cette question
        qui était posée... Qu'est-ce que nous sommes censés faire ici ? La
        société actuelle dans laquelle j'évolue au cours des années devient
        asphyxiante. On se forge un corps terrestre qui nous correspond pour
        cheminer dans cette vallée inconsciente. Il fallait opérer ce revirement
        fondamental. Cela devenait de plus en plus évident.
        <br />
        <br />
        De petit vers l'adolescence il y a eu une adaptation avec le monde pour
        ainsi dire rentrer dans le moule. Alors plus tard que veux tu faire
        comme métier ? etc etc... En réalité je n'en savais rien. Adulte j'ai
        enchaîné les boulots à droite à gauche et cela se terminait toujours par
        une démission.
        <br />
        <br /> Et cette question qui était posée... <br />
        <br />
        Ce monde n'a t'il pas de personnes emphatiques ?<br />
        <br /> Je pensais que ce n'était pas normal... comment mes collègues
        font, les voyant se détruire à petit feu depuis plusieurs années. Telle
        était la vision qui me frappait pendant cette longue  période.
      </p>
      <img src={imgOeil} alt="couché de soleil" />
      <h3>La rencontre avec un initié et des chamanes.</h3>
      <p>
        C'est en mai 2020 qu'il y a eu cette rencontre avec un initié. Au fur et
        à mesure des mois et années, jusqu'en Avril 2023, cette personne m'a
        transmis, lors d'initiations, des connaissances magiques et ésotériques.
        J'ai pu étudier certaines lectures et recevoir des enseignements en
        rapport avec l'hermétisme, l'ésotérisme et la gnose. Je te salue pour ta
        patience et ton intégrité, Merci J.B. Par la suite, pendant ces trois
        années mon cheminement m'a amené à rencontrer des praticiens et des
        chamanes, notamment Claude Degryse (éclaireur toltèque) qui traite d'un
        chamanisme de Carlos Castaneda. Corine Depeyrot (femme médecine),
        enseignements amérindiens. Ils ont à leur tour transmis leurs propres savoirs chamaniques
        et expériences. La partie visible de l'iceberg opère un retournement
        pour devenir la partie immergée. L'intérieur de l'intérieur... J'ai pu
        par la suite, recevoir les enseignements des esprits et des mondes
        chamaniques en incluant une pratique personnelle et très régulière
        (intensive). C'est très long et cela demande de la patience. La nature
        est une grande enseignante, elle offre beaucoup à celle ou celui qui
        honorera la dimension sacrée. Depuis fin 2020 le chamanisme s'est
        beaucoup expansé en pratiquant. Aujourd'hui, c'est cette voie, qui est
        une évidence dans le cœur, que j'ai décidé d'emprunter. Fin d'année
        2023, en décembre, j'ai été contacté personnellement par Enkhetuya. Les
        préparatifs sont en cours pour me rendre en Mongolie, pour la rencontrer
        et qui je l'espère, se fera sur l'année 2024. Le chemin continue.
        L'année 2024 promet une belle expansion d'apprentissage et de belles
        rencontres. Au jour d'aujourd'hui je ne pratique plus le chamanisme de
        Mickael Harner, d'un point de vue purement personnel, cela ne me
        convient plus. C'était un passage obligé pour en tirer un enseignement
        de sagesse. Le chamanisme tire sa force des racines des grands arbres
        majestueux, de la terre et du ciel ou chaque homme devrait s'abreuver
        depuis les racines et les hauteurs des montagnes. Il sème le blé dans
        son propre champ, afin que la culture soit robuste et pousse en
        direction du soleil. Ainsi l'homme se délectera de la douceur du nectar
        lumineux. Aujourd'hui je serais heureux de vous recevoir en
        consultation, afin de vous proposer mes services chamaniques.
      </p>
      {/* <p>
        C'est en mai 2020 qu'il y a eu cette rencontre avec un initié. Au fur et
        à mesure des mois et années. Jusqu'en Avril 2023. Cette personne m'a
        transmis, oralement et vibratoirement lors de soins et d'initiations des
        connaissances magiques et ésotériques. Je l'en remercie. Par la suite,
        pendant c'est trois années mon cheminement m'a amené à rencontrer des
        chamanes qui ont à leur tour transmis leurs propre savoirs chamaniques et
        expériences. La partie visible de l'iceberg opère un retournement pour
        devenir la partie immergée. L'intérieur de l'intérieur... J'ai pu par la
        suite, recevoir les enseignements des esprits et des mondes chamaniques
        lors de certains stages et en incluant une pratique personnelle et
        régulière. Depuis fin 2020 le chamanisme s'est beaucoup expansé en
        pratiquant. Au jour d'aujourd'hui, c'est cette voix, qui est une
        évidence dans le cœur, que j'ai décidé d'emprunter.
        <br />
        <br />
        Le haut et le bas et le bas et le haut.
        <br />
        <br />
        La Terre et le ciel et le ciel et la terre.
      </p> */}
      <div className="separation"></div>
      <h3>
        Pourquoi le chamanisme, la transmission, la fabrication de tambours et
        les soins ?
      </h3>
      <p>
        Des reconnexions avec de la pratique qui ont amené un équilibre et qui
        aujourd’hui font partie intégrante de cette vie riche en enseignements.
        Par la suite cela amène à transmettre lors d'initiation pendant les
        stages et séjours, pour les personnes qui souhaitent évoluer et qui
        contribueront à l’élévation avec le coeur et la lumière. Pour la partie
        soin chamanique, la force vibratoire qui sera déployée pendant les
        séances de soins et autres, vous permettra d’aller plus loin vers des
        déblocages et un mieux être.
      </p>
      <img className="arbreimg" src={imgFeu} alt="Benjamin LAFFORGUE" />
      <img src={imgTexte} alt="couché de soleil" />
      <p>Merci et bonne visite</p>
    </div>
  );
}

export default About;
